import checkGlobalSiteTagDefined from './common';

const ADWORDS_ID = 'AW-384894805';

/**
 * Report lead form conversion to Adwords.
 */
const reportSubmitLeadFormConversionPage = () => {
  if (!checkGlobalSiteTagDefined()) {
    return null;
  }

  window.gtag('event', 'conversion', {
    send_to: `${ADWORDS_ID}/hAYGCMngvIcCENWOxLcB`,
  });
};

export { reportSubmitLeadFormConversionPage };
