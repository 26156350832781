import React from 'react';
import styled from 'styled-components';

import { H1 } from '_templates/LandingSection';
import colours from '_theming/colours';
import InternalLinkButton from '_components/InternalLinkButton';

/**
 * Custom H1.
 */
const CustomH1 = styled(H1)`
  margin: 35px 0;
`;

/**
 * Page container.
 */
const Container = styled.main`
  display: flex;
  flex-flow: column nowrap;

  justify-content: center;
  align-items: center;

  text-align: center;
`;

/**
 * Contact Sales Thank You section.
 *
 * @returns {React.Component} Section.
 */
const ContactSalesThankYou = () => (
  <Container>
    <CustomH1 textAlign="center">Your message has been sent! We will contact you shortly.</CustomH1>

    <InternalLinkButton to="/" bgcolor={colours.redDamask}>
      Return Home
    </InternalLinkButton>
  </Container>
);

export default ContactSalesThankYou;
