import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
  Margin,
  ContainerNavigation,
  ContainerFooter,
  ContainerTinySectionDivider,
  ContainerTinyFooterDivider,
} from '_templates/Pages';
import colours from '_theming/colours';
import ContactSalesTeamThankYou from '_sections/ContactSales/ThankYou';
import Curved from '_components/Curved';
import Footer from '_sections/Footer';
import Layout from '_components/Layout';
import NavigationBar from '_components/NavigationBar';
import { reportSubmitLeadFormConversionPage as reportLeadConversionToAdwords } from '_analytics/gtag/adwords';
import { reportSubmitLeadFormConversionPage as reportLeadConversionToGA4 } from '_analytics/gtag/ga4';

/**
 * Page container.
 */
const ContainerPage = styled.main`
  display: flex;
  flex-flow: column nowrap;

  justify-content: space-between;

  min-height: 100vh;
`;

/**
 * Container for each section.
 */
const ContainerSection = styled.div`
  background-color: ${(props) => props.bgColor || colours.white};
`;

/**
 * Contact Sales Thank You page.
 *
 * @returns {React.Component} Contact Sales Thank You page.
 */
const ContactSalesThankYouPage = () => {
  // Report to analytics provider on page load.
  useEffect(() => {
    reportLeadConversionToAdwords();
    reportLeadConversionToGA4();
  }, []);

  return (
    <Layout>
      <title>Contact Us - Pricematepay</title>

      <ContainerPage>
        <div>
          <ContainerNavigation>
            <Margin>
              <NavigationBar />
            </Margin>
          </ContainerNavigation>

          <ContainerTinySectionDivider>
            <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
          </ContainerTinySectionDivider>
        </div>

        <ContainerSection bgColor={colours.white}>
          <Margin>
            <ContactSalesTeamThankYou />
          </Margin>
        </ContainerSection>

        <div>
          <ContainerTinyFooterDivider>
            <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
          </ContainerTinyFooterDivider>

          <ContainerFooter bgColor={colours.daintree}>
            <Margin>
              <Footer />
            </Margin>
          </ContainerFooter>
        </div>
      </ContainerPage>
    </Layout>
  );
};

export default ContactSalesThankYouPage;
