import checkGlobalSiteTagDefined from './common';

/**
 * Report lead form conversion to GA4.
 */
const reportSubmitLeadFormConversionPage = () => {
  if (!checkGlobalSiteTagDefined()) {
    return null;
  }

  window.gtag('event', 'contact_sales');
};

export { reportSubmitLeadFormConversionPage };
